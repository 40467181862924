import { FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { MdClose, MdInfo } from 'react-icons/md';
import CircularProgress from '@material-ui/core/CircularProgress';
import useRouter from 'next/router';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useTranslation from 'next-translate/useTranslation';
import DropdownIcon from 'public/assets/svg/chevron_down.svg';
import { checkVerifyEmail, logout, resendConfirmationEmail } from 'redux-saga/actions';
import { AppState } from 'redux-saga/interfaces';

const useStyles = makeStyles((theme) => ({
  close: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

interface Props {
  isSidebar?: boolean;
}

const MHeaderDropdown: FC<Props> = ({ isSidebar }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isShowVerifyEmail, setIsShowVerifyEmail] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');
  const { profileInfo } = useSelector((state: AppState) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [isResent, setIsResent] = useState(false);

  useEffect(() => {
    const verifyEmailPages = [
      '/onboarding',
      '/my-cvs',
      '/settings',
      '/choose-template',
      '/onboarding/steps',
      '/settings/transactions',
    ];
    const isPageNeedVerify = verifyEmailPages.includes(useRouter.pathname);
    const callbacks = {
      onSuccess: ({ verified }) => setIsShowVerifyEmail(!verified),
    };
    if (!profileInfo.verified && isPageNeedVerify) dispatch(checkVerifyEmail(callbacks));
  }, []);

  const onResendEmail = () => {
    const callbacks = {
      onBegin: () => setIsLoading(true),
      onFinish: () => setIsLoading(false),
      onSuccess: () => {
        setIsResent(true);
        enqueueSnackbar(t('common.successfullyResent'), { variant: 'success', autoHideDuration: 6000 });
      },
    };
    dispatch(resendConfirmationEmail({ email: profileInfo.email.trim() }, callbacks));
  };

  const handleExpandClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const handleOpenDropdown = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const onLogout = () => dispatch(logout());
  const goToSettings = () => useRouter.push('/settings');
  const goToMyCV = () => useRouter.push('/my-cvs');
  const goToContactUs = () => window.open('http://m.me/urbancv', '_blank');
  const goToReferral = () => useRouter.push('/referral-program');

  const verifyEmailPanel = () => {
    return (
      <Box
        bgcolor="#E2584F"
        className="u-center-self"
        color="white"
        borderRadius={4}
        position="fixed"
        left="50%"
        top={50}
      >
        <Box padding="8px 8px 8px 16px" justifyContent="space-between" display="flex" alignItems="center" gridGap={10}>
          <MdInfo size={20} />
          <Box className="o-body-regular2" color="white">
            {t('mHeaderDropdown.verifyDescription')}
          </Box>
          {isLoading ? (
            <Box color="white" component="span" m={4}>
              <CircularProgress size={22} color="inherit" />
            </Box>
          ) : (
            <>
              {!isResent && (
                <Box className="o-body-regular2 u-text-underline u-pointer" color="white" onClick={onResendEmail}>
                  {t('mHeaderDropdown.resend')}
                </Box>
              )}
              <IconButton className={classes.close} onClick={() => setIsShowVerifyEmail(false)} color="inherit">
                <MdClose size={20} />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <div className="m-header-dropdown">
      {isShowVerifyEmail && verifyEmailPanel()}
      {isSidebar ? (
        <>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className="c-primary-button c-primary-button--small-width c-primary-button--no-shadow"
            onClick={handleExpandClick}
          >
            {t('mHeaderDropdown.myAccount')}
            <Box component="span" ml={4} width={20} height={20} className={expanded ? 'u-scale-y--1' : ''}>
              <DropdownIcon />
            </Box>
          </Button>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List className="header-menu">
              <ListItem onClick={goToMyCV}>{t('mHeaderDropdown.myCvs')}</ListItem>
              <ListItem onClick={goToSettings}>{t('mHeaderDropdown.settings')}</ListItem>
              <ListItem onClick={goToContactUs}>{t('mHeaderDropdown.contactUs')}</ListItem>
              <ListItem onClick={goToReferral}>{t('mHeaderDropdown.freeUpgrade')}</ListItem>
              <ListItem onClick={onLogout}>{t('mHeaderDropdown.logOut')}</ListItem>
            </List>
          </Collapse>
        </>
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            className="c-primary-button c-primary-button--small-width c-primary-button--no-shadow"
            onClick={handleOpenDropdown}
          >
            <Box component="span" whiteSpace="nowrap">
              {t('mHeaderDropdown.myAccount')}
            </Box>
            <Box component="span" ml={4} width={20} height={20} className={anchorEl ? 'u-scale-y--1' : ''}>
              <DropdownIcon />
            </Box>
          </Button>
          <Menu
            className="header-menu"
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handleCloseDropdown}
          >
            <MenuItem onClick={goToMyCV}>{t('mHeaderDropdown.myCvs')}</MenuItem>
            <MenuItem onClick={goToSettings}>{t('mHeaderDropdown.settings')}</MenuItem>
            <MenuItem onClick={goToContactUs}>{t('mHeaderDropdown.contactUs')}</MenuItem>
            <MenuItem onClick={goToReferral}>{t('mHeaderDropdown.freeUpgrade')}</MenuItem>
            <MenuItem onClick={onLogout}>{t('mHeaderDropdown.logOut')}</MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};

export default MHeaderDropdown;
